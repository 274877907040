<script>
	import { Router } from "@sveltech/routify";
	import { routes } from "../.routify/routes";
	import { onMount } from 'svelte'
	import { site } from './store.js'
	let data = []
	onMount(async () => {
		const res = await fetch('https://konepalvelusorsa.fi/pw/');
		data = await res.json()
		site.set(data)
	})
</script>
<Router {routes} />